export function getNewObject(object, keyName, keyAdditionalName = null) {
  if (keyAdditionalName) {
    return object[keyName][keyAdditionalName].map(item => {
      let newObj = {
        id: null,
        name: item
      }
      item = newObj
      console.log('additional', item)
      return item
    })
  } else {
    if (Array.isArray(object[keyName])) {
      return object[keyName].map(item => {
          let newObj = {
            id: null,
            name: item
          }
          item = newObj
          console.log('array', item)
          return item
      })
    } else {
      let newObj = {
        id: null,
        name: object[keyName]
      }
      object[keyName] = newObj
      console.log('obj', object[keyName])
      return object[keyName]
    }
  }
}
/**
 * @param {[]} array []
 * @param {string, {}[]} key 'name'
 * @param {string} separator ', '
 * @param {string} emptyText = ''
 * */
export function getNamesFromArray(array, key = 'name', separator = ', ', emptyText = '') {
  if (!array  || !Array.isArray(array)) return emptyText;
  if (!array.length)
      return  emptyText;
  return array.map(el => {
      if (typeof key === 'string') return el[key];
      else if (Array.isArray(key)) {
        let str= '';
        key.forEach((k, i) => {str += el[k] + (i === (key.length -1 ) ? ' ' : ' - ')})
        return str;
      }
      return el[key];
}).join(separator);
}
export function formatDate(date = '') {
  if(!date) return ''
  return date
}
export function getStringFromBoolean(bool) {
  return bool ? 'Да' : 'Нет'
}