import {getUserFiles, getUserInfo, setUserInfo} from "@/Api/userInfo";
import userInfo from "@/utils/userInfo";
export default {
    namespaced: true,
    state: {
        status: false,
        user: null,
        fakeUser: null,
        userFiles: [],
        userInfo: null,
        loading: false,
        accessToken: null,
        error: null,
        replaceIdAdmin: null,
        replaceRoleIdAdmin: null,
        actualOrgStatus: null,
        loadingUserInfo: false,
        errorUserInfo: null, 
        loadingUserFiles: false,
        errorUserFiles: null
    },
    actions: {
        replaceIdAdmin({commit}, payload) {
            commit('REPLACE_ID_ADMIN', payload.orgID);
            commit('REPLACE_ROLE_ID_ADMIN', payload.role)
        },
        async logout({commit}) {
            commit('LOG_OUT');
            localStorage.removeItem('auth');
            commit('LOADING', false)
        },
        async saveUserInfo({commit, rootGetters}, payload) {
            try {
                if (rootGetters['keycloak/isAdminGroup']) return;
                commit('USER_INFO_LOADING', true);
                const res = await setUserInfo(payload);
                commit('USER_INFO_SUCCESS', res);
                commit('USER_INFO_LOADING', false);
                return res;
            } catch (e) {
                commit('USER_INFO_ERROR', e);
                commit('USER_INFO_LOADING', false);
            }
        },
        async getUserInfo({commit}) {
            try {
                commit('USER_INFO_LOADING', true);
                const res = await getUserInfo();
                commit('USER_INFO_SUCCESS', res);
                commit('USER_INFO_LOADING', false);
            } catch (e) {
                commit('USER_INFO_ERROR', e);
                commit('USER_INFO_LOADING', false);
            }
        },
        initFakeUser({commit}, payload) {
            commit('INIT_FAKE_USER', payload)
        },
        clearFakeUser({commit}) {
            commit('INIT_FAKE_USER', null)
        },
        async setUserFiles({commit}) {
            try {
                commit('USER_FILES_LOADING', true);
                const res = await getUserFiles();
                commit('USER_FILES_SUCCESS', res);
                commit('USER_FILES_LOADING', false);
            } catch (e) {
                commit('USER_FILES_ERROR', e);
                commit('USER_FILES_LOADING', false);
            }
        },
        deleteFileFromStore({commit}, id){
            commit('USER_FILES_DELETE', id)
        }
    },
    mutations: {
        ['USER_INFO_SUCCESS'](state, payload) {
            state.userInfo = payload;
            state.errorUserInfo = null;
        },
        ['INIT_FAKE_USER'](state, data) {
            state.fakeUser = data;
        },
        ['USER_FILES_SUCCESS'](state, payload) {
            state.userFiles = payload;
            state.errorUserFiles = null;
        },
        ['USER_FILES_DELETE'](state, payload) {
            const ind = state.userFiles.findIndex(el => el.id === payload);
            state.userFiles.splice(ind, 1)
        },
        ['LOG_OUT'](state) {
            state.status = false;
            state.user = null;
            state.error = null;
            state.accessToken = null;
            state.userInfo = null;
            state.userFiles = null;
            state.errorUserInfo = null;
            state.errorUserFiles = null;
        },
        ['ERROR'](state, payload) {
            state.error = payload;
            state.status = false;
        },
        ['LOADING'](state, payload) {
            state.loading = payload
        },
        ['USER_INFO_ERROR'](state, payload) {
            state.errorUserInfo = payload;
        },
        ['USER_INFO_LOADING'](state, payload) {
            state.loadingUserInfo = payload
        }, 
        ['USER_FILES_ERROR'](state, payload) {
            state.errorUserFiles = payload;
        },
        ['USER_FILES_LOADING'](state, payload) {
            state.loadingUserFiles = payload
        },
        ['REPLACE_ID_ADMIN'](state, payload) {
            state.replaceIdAdmin = payload
        },
        ['REPLACE_ROLE_ID_ADMIN'](state, payload) {
            state.replaceRoleIdAdmin = payload
        },
        ['ACTUAL_ORG_STATUS'](state, payload) {
            state.actualOrgStatus = payload
        },
    },
    getters: {
        authData(state) {
            return state.user
        },
        getFakeUser(state) {
            return state.fakeUser
        },
        getUserFiles(state) {
            return state.userFiles
        },
        getUserFilesLoading(state) {
            return state.loadingUserFiles
        },
        getUserInfo(state) {
            return state.userInfo ??  {...userInfo}
        },
        isUserBlock(state,/* _, __, rootGetters*/){
            //orgId Madi: 260 novosib technic: 349
            return /*[].includes(rootGetters['keycloak/getAuthData']?.tokenParsed?.orgID) ? */ (state.userInfo?.blocked || state.userInfo?.blockedWithoutData)// :  false; //
        },
        getUserInfoLoading(state) {
            return state.loadingUserInfo
        },
        getAuthUserDataLoading(state) {
            return state.loadingUserInfo
        },
        getAuthUserDataError(state) {
            return state.errorUserInfo
        },


    },
}