export default {
  namespaced: true,
  state: {
    sections: [
      {
        id: 1,
        title: 'Раздел 1. Данные об олимпиадах школьников с участием иностранных граждан (кроме Всероссийской олимпиады школьников).',
        path: 'section-1',
        forms: [
          {
            id: 1,
            sectionId: 1,
            title: 'Форма 1. Информация об организованных в 2022/23 учебном году олимпиадах школьников, участие в которых принимали иностранные граждане, обучавшиеся в 11 классе (в выпускном классе).',
            number: '1',
            path: 'form-1',
          },
          {
            id: 2,
            sectionId: 1,
            title: 'Форма 1.1. Информация о профилях организованных в 2022/23 учебном году олимпиад школьников, участие в которых принимали иностранные граждане, обучавшиеся в 11 классе (в выпускном классе).',
            number: '1.1',
            path: 'form-1.1',
          },
          {
            id: 3,
            sectionId: 1,
            title: 'Форма 2. Информация об олимпиадах школьников, планируемых к проведению в 2023/24 учебном году, в которых смогут принять участие иностранные граждане, обучающиеся в 11 классе (в выпускном классе).',
            number: '2',
            path: 'form-2',
          },
          {
            id: 4,
            sectionId: 1,
            title: 'Форма 3. Информация о потребности в количестве мест на 2024/25 учебный год, которые по результатам проведения олимпиад школьников планируется предоставить иностранным гражданам в целях отбора на обучение в переделах квоты Правительства Российской Федерации.',
            number: '3',
            path: 'form-3',
          },
          // {
          //   id: 5,
          //   sectionId: 1,
          //   title: 'Форма3.1 Информация о потребности в количестве мест на 2023/24 учебный год по профилю, которые по результатам проведения олимпиад школьников планируется заполнить иностранными гражданами в целях обучения в пределах квоты Правительства Российской Федерации',
          //   number: '3.1',
          //   path: 'form-3.1',
          // }
        ]
      },
      {
        id: 2,
        title: 'Раздел 2. Данные об олимпиадах для лиц, претендующих на обучение по образовательным программам высшего образования – программам магистратуры, с участием иностранных граждан.',
        path: 'section-2',
        forms: [
          {
            id: 1,
            sectionId: 2,
            title: 'Форма 1. Информация об организованных в 2022/23 учебном году олимпиадах для лиц, претендующих на обучение по образовательным программам высшего образования – программам магистратуры, участие в которых принимали иностранные граждане, обучавшиеся на последнем курсе бакалавриата/специалитета.',
            number: '1',
            path: 'form-1',
          },
          {
            id: 2,
            sectionId: 2,
            title: 'Форма 1.1 Информация о профилях организованных в 2022/23 учебном году олимпиад для лиц, претендующих на обучение по образовательным программам высшего образования – программам магистратуры, участие в которых принимали иностранные граждане, обучавшиеся на последнем курсе бакалавриата/специалитета.',
            number: '1.1',
            path: 'form-1.1',
          },
          {
            id: 3,
            sectionId: 2,
            title: 'Форма 2. Информация об олимпиадах для лиц, претендующих на обучение по образовательным программам высшего образования – программам магистратуры, планируемых к проведению в 2023/24 учебном году, в которых смогут принять участие иностранные граждане, обучающиеся на последнем курсе бакалавриата/специалитета.',
            number: '2',
            path: 'form-2',
          },
          {
            id: 4,
            sectionId: 2,
            title: 'Форма 3. Информация о потребности в количестве мест на 2024/25 учебный год, которые по результатам проведения олимпиад для лиц, претендующих на обучение по образовательным программам высшего образования – программам магистратуры, планируется предоставить иностранным гражданам в целях отбора на обучение в пределах квоты Правительства Российской Федерации.',
            number: '3',
            path: 'form-3',
          },
          // {
          //   id: 5,
          //   sectionId: 2,
          //   title: 'Информация о потребности в количестве мест на 2023/24 учебный год по профилю, которые по результатам проведения олимпиад для лиц, поступающих в магистратуру, планируется заполнить иностранными гражданами в целях обучения в пределах квоты Правительства Российской Федерации',
          //   number: '3.1',
          //   path: 'form-3.1',
          // }
        ]
      }
    ],
  },
  actions: {},
  mutations: {},
  getters: {
    getSectionsData(state) {
      return state.sections.map(el => {
        return {...el, forms: el.forms/*.filter((_, i ) => i < 3)*/}
      })
    },
  },
}